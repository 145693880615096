<template>
  <div>
    <span class="render-markers">
      <Icons name="marker-2w" />
      <Icons name="marker-3w" />
      <Icons name="marker-4w" />
    </span>
    <!-- <img
      src="../../../../assets/icons/map-markers/truck.png"
      alt=""
    /> -->

    <l-map
      v-if="routeCoordinates.length > 0"
      class="VueLeafMap"
      :zoom="zoom"
      :center="MapCenter"
      :bounds="bounds"
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />
      <l-control-fullscreen
        position="topright"
        :options="{ title: { false: 'Full Screen', true: 'Dashboard View' } }"
      />
      <v-marker-cluster>
        <template v-for="(point, i) in routeCoordinates">
          <l-marker
            :key="i"
            :lat-lng="point"
            :icon="clusterIcon"
            @click="showPopupMarkers"
          >
            <l-icon>
              <span>
                <Icons
                  size="map-marker-size"
                  :name="getIcon(point)"
                  :class="getVehicleMarkerColor(point)"
                />
                <!-- <v-icon :color="getVehicleMarkerColor(point)">
                mdi-circle
              </v-icon> -->
                <!-- :style="getVehicleMarkerColor(point)" -->
                <div
                  class="map-icon-text"
                  :title="getCIT(point)"
                >
                  {{ getCIT(point) }}
                </div>
              </span>
            </l-icon>

            <!--  -->
            <l-popup>
              <div class="">
                Latitude:
                <span>{{ popupLatLng.lat?.toFixed(4) }}</span>
              </div>
              <div class="">
                Longitude:
                <span>{{ popupLatLng.lng?.toFixed(4) }}</span>
              </div>
              <div
                v-if="popupLatLng.spd"
                class=""
              >
                Speed:
                <span>
                  {{ parseFloat(popupLatLng?.spd).toFixed(2) }} km/h
                </span>
              </div>
              <div class="">
                <!-- {{
                popupLatLng.vehicle_tech !== "ice"
                  ? "Customer Name:"
                  : "Driver Name:"
              }} -->
                {{ product === "oem" ? "Customer Name:" : "Driver Name:" }}
                <span>{{ popupLatLng?.dn }}</span>
              </div>
              <div
                v-if="popupLatLng.vt !== 'ice'"
                class=""
              >
                SOC:
                <span>{{ popupLatLng.soc }}</span>
              </div>
              <div class="">
                Ignition:
                <span>{{ popupLatLng.ign_s }}</span>
              </div>
              <div class="">
                Status:
                <span>{{ popupLatLng.st }}</span>
              </div>

              <div class="">
                Date Time:
                <span>{{ popupLatLng.sync_at }}</span>
              </div>
            </l-popup>
            <!--  -->
          </l-marker>
        </template>
      </v-marker-cluster>
    </l-map>
  </div>
</template>

<script>
// import Vue from "vue";
// Vue.use(require("vue-pusher"), {
//   api_key: "336be4c75dc18eab67eb",
//   options: {
//     cluster: "ap2",
//     encrypted: true,
//   },
// });

import Vue from "vue";
import VuePusher from "vue-pusher";

Vue.use(VuePusher, {
  api_key: process.env.VUE_APP_PUSHER_KEY,
  options: {
    cluster: "ap2",
    forceTLS: true,
    // encrypted: true,
  },
});
// import L from "leaflet";
import { latLngBounds } from "leaflet";
import { Icon, icon } from "leaflet";
import LControlFullscreen from "vue2-leaflet-fullscreen";
import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LPopup,
  //   LPolyline,
} from "vue2-leaflet";
import { mapGetters } from "vuex";

import iconUrl from "leaflet/dist/images/marker-icon.png";
import shadowUrl from "leaflet/dist/images/marker-shadow.png";
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
    // LPolyline,
    LControlFullscreen,
    Icons: () => import("@/components/base/icons.vue"),
    //
    "v-marker-cluster": () =>
      import("@/components/sections/Vue2LeafletMarkercluster.vue"),
  },
  props: {
    channelName: {
      type: String,
      default() {
        return "";
      },
    },
    product: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    let customicon = icon(
      Object.assign({}, Icon.Default.prototype.options, { iconUrl, shadowUrl })
    );
    return {
      // url: "",
      // attribution: "",
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?lang=en",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 12,
      markerLatLng: [24.821428, 67.038277],
      popupLatLng: [],

      clusterIcon: customicon,
      // bounds: latLngBounds([
      //   [40.70081290280357, -74.26963806152345],
      //   [40.82991732677597, -74.08716201782228],
      // ]),

      //
      counter: 0,
      vehiclesData: [],
      // fourWheelIcon: require("@/assets/icons/map-markers/truck.png"),
      iconSize: [32, 37],
      iconAnchor: [16, 37],
      // fourwheel: L.icon({
      //   iconUrl: require("@/assets/icons/map-markers/truck.png"),
      //   iconSize: [32, 37],
      //   iconAnchor: [16, 37],
      // }),
      staticAnchor: [16, 37],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
    }),
    routeCoordinates() {
      const arr = this.vehiclesData.map((r) => [r.lt, r.ln]);

      // return arr.filter((r) => r[0]);
      return arr;
    },
    MapCenter() {
      return this.routeCoordinates[0] ?? [24.8785, 67.0641];
    },
    bounds() {
      // let arr = [];
      // const routeLength = this.routeCoordinates.length - 1;
      // arr.push(this.routeCoordinates[0]);
      // arr.push(this.routeCoordinates[routeLength]);
      return latLngBounds(this.routeCoordinates);
    },
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },
  },
  watch: {
    channelName(newVal, oldVal) {
      this.vehiclesData = [];
      this.$pusher.unsubscribe(oldVal);
      this.fetchData(newVal);
    },
  },
  mounted() {
    this.fetchData();
  },
  // beforeDestroy() {
  //   this.$pusher.unsubscribe(this.channelName);
  // },
  methods: {
    fetchData() {
      this.$pusher.unsubscribe(this.channelName);
      // Subscribe to Pusher channel
      const channel = this.$pusher.subscribe(this.channelName);
      // channel.bind("latest-vehicles-Business-79", (data) => {
      channel.bind(`latest-vehicles-${this.channelName}`, (data) => {
        // Update the marker position based on Pusher event data
        // this.markerLatLng = [data.latitude, data.longitude];
        this.vehiclesData = data;
      });
    },
    getVehicleMarkerColor(val) {
      const detail = this.vehiclesData.find(
        (r) => r.lt == val[0] && r.ln == val[1]
      );
      //Color from Status Box Components

      return detail?.st;
      // if (detail.st == "active") return { "background-color": "green" };
      // else if (detail.st == "in_active")
      //   return { "background-color": "#f0ae38" };
      // else if (detail.st == "offline") return { "background-color": "#ff0000" };
    },
    getCIT(pnt) {
      const detail = this.vehiclesData.find(
        (r) => r.lt == pnt[0] && r.ln == pnt[1]
      );

      return detail?.dn;
    },
    getIcon(pnt) {
      // const params = {
      //   show: false,
      // };
      // const fourWheel = ["Mustangeye", "Mustang-Fleet"];
      const detail = this.vehiclesData.find(
        (r) => r.lt == pnt[0] && r.ln == pnt[1]
      );
      // return `marker-${detail?.mt}`;
      return `marker-${detail?.mt}`;
      // if (fourWheel.includes(detail.tn)) {
      //   params.show = true;
      //   params.icon = L.icon({
      //     iconUrl: require("@/assets/icons/map-markers/truck.png"),
      //     iconSize: [32, 37],
      //     iconAnchor: [16, 37],
      //   });
      //   return params;
      // } else {
      //   return params;
      // }
    },
    showPopupMarkers(event) {
      // Get the coordinates of the clicked point
      const latlng = event.latlng;
      const detail = this.vehiclesData.find(
        (r) => r.lt == latlng.lat && r.ln == latlng.lng
      );
      // Set the popup coordinates and content
      this.popupLatLng = { ...latlng, ...detail };
    },
    //     getPolylineOptions() {
    //       const polylineOptions = {
    //         color: "green",
    //         weight: 3,
    //         opacity: 0,
    //       };

    //       return polylineOptions;
    //     },
    //     showPopupPolyline(event) {
    //       // Get the coordinates of the clicked point
    //       const latlng = event.latlng;
    //       this.popupLatLng = latlng;
    //     },
  },
};
</script>

<style lang="sass" scoped>
.VueLeafMap
    z-index: 1 !important
    height: 400px
:deep .leaflet-popup-content-wrapper
  .leaflet-popup-content
    width: max-content !important


//   position: absolute !important
//   width: 100% !important
//   height: 100% !important
//   top: 0
//   left: 0
// :deep .leaflet-marker-icon.leaflet-zoom-animated.leaflet-interactive
//   display: flex
//   justify-content: center

.custom-icon-img
  width: 40px !important
.map-icon-text
  background: #fff
  border-radius: 2px
  // display: flex
  width: 65px
  font-size: x-small
  font-weight:800
  padding: 2px
    /* display: inline-block; */
  align-items: center
  justify-content: center

  text-wrap: nowrap
  overflow: hidden
  text-overflow: ellipsis



// Marker Colors
// .marker-4w
//   svg g
//     fill: #000

:deep .active svg g
  fill : #35af35 !important
  stroke: black !important
  stroke-width: 5rem !important

:deep .in_active svg g
  fill : #f0ae38 !important
  stroke: black !important
  stroke-width: 5rem !important

:deep .offline svg g
  fill : #df4f4f
  stroke: black !important
  stroke-width: 5rem !important

// Marker Colors



.render-markers
  position: absolute
  opacity: 0 !important
</style>
